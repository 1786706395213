import { Nav } from 'react-bootstrap';

import { ISidebarItem, SIDEBAR_ITEMLIST } from '@/constants/sidebarItem';

import '@/components/sidebar/sidebar.scss';

import ItemLink from '@/components/sidebar/item/item';

export interface SidebarProps {
  appVersion: string;
  isSidebarCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({isSidebarCollapsed }) => {
  const sidebarItems: ISidebarItem[] = SIDEBAR_ITEMLIST;

  return (
    <div className={`sidebar${isSidebarCollapsed ? ' collapsed' : ''}`}>
      <div className="center">
        <Nav activeKey="/home">
          {sidebarItems.map((item) => (
            <ItemLink key={item.path} {...item} />
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
