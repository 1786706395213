import Loadable from 'react-loadable';
import Loading from '@/components/loading';

const LoadableTestPlan = Loadable({
  loader: async () => import('@/pages/testPlan'),
  loading: Loading
});
const LoadableHome = Loadable({
  loader: async () => import('@/pages/home'),
  loading: Loading
});
const LoadableDashboard = Loadable({
  loader: async () => import('@/pages/home'),
  loading: Loading
});
const LoadableLogin = Loadable({
  loader: async () => import('@/pages/loginPage/loginPage'),
  loading: Loading
});
const LoadableModal = Loadable({
  loader: async () => import('react-bootstrap/Modal'),
  loading: Loading
});
const LoadableUserManagement = Loadable({
  loader: async () => import('@/pages/userManagementPage/userManagementPage'),
  loading: Loading
});
const LoadableProfile = Loadable({
  loader: async () => import('@/pages/profilePage/profilePage'),
  loading: Loading
});
const LoadableSetting = Loadable({
  loader: async () => import('@/pages/settingPage/settingPage'),
  loading: Loading
});

const LoadableReport = Loadable({
  loader: async () => import('@/pages/reportPage/reportPage'),
  loading: Loading
});
const LoadableAdminManagement = Loadable({
  loader: async () => import('@/pages/adminAccountManagementPage/adminAccountManagementPage'),
  loading: Loading
});
const LoadableAdminAccountEditPage = Loadable({
  loader: async () => import('@/pages/adminAccountManagementPage/adminAccountEditPage'),
  loading: Loading
});

export const TestPlan = LoadableTestPlan;
export const Home = LoadableHome;
export const Dashboard = LoadableDashboard;
export const ModalDialog = LoadableModal;
export const LoginPage = LoadableLogin;
export const UserManagement = LoadableUserManagement;
export const ProfilePage = LoadableProfile;
export const SettingPage = LoadableSetting;
export const ReportPage = LoadableReport;
export const AdminAccountManagement = LoadableAdminManagement;
export const AdminAccountEdit = LoadableAdminAccountEditPage;
