import { SvgIconTypeMap } from '@mui/material/SvgIcon';

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
import { OverridableComponent } from '@mui/material/OverridableComponent';

// React-icons
// https://react-icons.github.io/react-icons
import { BsPeople, BsClipboard2Check } from 'react-icons/bs';

// We can also add custom SVG here...
import { IconType } from 'react-icons';
import { PATH } from '@/constants/path';
import AdminRoleEnum from '@/enum/adminRole';
import { getUserToken } from '@/utils/authUtil';

const user = getUserToken();
const userRole = user?.role_id as AdminRoleEnum ?? null;
export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | IconType | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  group: ISidebarItem[];
  notAllowRole: AdminRoleEnum[] | null;
}

const checkGroupItem = () => {
  const item =  []

  if (+userRole !== AdminRoleEnum.STAFF) {
    item.push({
      path: PATH.MANAGE_ADMIN,
      text: 'USER_MANAGEMENT_PAGE_ADMIN',
      isgroup: false,
      group: [],
      notAllowRole: null,
    })
  }

  item.push({
    path: PATH.MANAGE_MEMBER,
    text: 'USER_MANAGEMENT_PAGE_MEMBER',
    isgroup: false,
    group: [],
    notAllowRole: null,
  })

  return item
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.HOME,
    text: 'USER_MANAGEMENT_PAGE_REPORT',
    icon: BsClipboard2Check,
    isgroup: false,
    group: [],
    notAllowRole: null,
  },
  {
    text: 'USER_MANAGEMENT_PAGE_MANAGE_USER',
    icon: BsPeople,
    isgroup: true,
    notAllowRole: null,
    group: checkGroupItem()
  }
];
