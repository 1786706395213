

function Loading(): JSX.Element {
  return (
    <div className="container-loading-page  m-auto">
      <div className="background" />
      <div className="container-card">
        <div className="spinner-border text-primary mb-2" role="status" />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
