import { FC } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast: FC = () => (
  <ToastContainer
    position="bottom-left"
    autoClose={4000}
    hideProgressBar
    newestOnTop={false}
    transition={Slide}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable={false}
    pauseOnHover
  />
);
export default Toast;
