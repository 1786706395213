import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import Navbar from '@/components/navbar/navbar';
import Sidebar, { SidebarProps } from '@/components/sidebar/sidebar';
import { removeToken } from '@/utils/authUtil';

import 'react-datepicker/dist/react-datepicker.css';
import { PATH } from '@/constants/path';

function MainLayout() {
  const APP_VERSION = '1.0.0';
  const { i18n } = useTranslation();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [language] = useState(localStorage.getItem('language') ?? 'th');
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, [i18n, language]);

  const toggleSidebar = (): void => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarProps: SidebarProps = {
    appVersion: APP_VERSION,
    isSidebarCollapsed
  };

  const signout = () => {
    removeToken();
    navigate(PATH.LOGIN)
  }

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} signout={signout}/>
      <div className="app">
        <Sidebar {...sidebarProps} />
        <div className="app-container">
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default MainLayout;
